
import { Vue, Component, Prop } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
import AuthService, { AuthServiceS } from '@/modules/auth/auth.service';
import CustomLoader from '@/modules/common/components/ui-kit/custom-loader.vue';
import OpenTelemetryService, { OpenTelemetryServiceS } from '@/modules/open-telemetry/open-telemetry.service';
import type StoreFacade from '@/modules/common/services/store-facade';
import type UserStore from '@/modules/user/store/user.store';
import type UserService from '@/modules/user/user.service';
import type NotificationService from '@/modules/notifications/notification.service';

@Component({
    components: {
        CustomLoader,
    },
})
export default class AuthPage extends Vue {
    @inject(AuthServiceS) private authService!: AuthService;
    @inject(OpenTelemetryServiceS) private otelService!: OpenTelemetryService;
    @inject(KEY.StoreFacade) private storeFacade!: StoreFacade;
    @inject(KEY.UserService) private userService!: UserService;
    @inject(KEY.NotificationService) private notificationService!: NotificationService;

    @Prop() private token!: string;
    @Prop() private postAuth!: boolean;

    readonly userStoreState: UserStore = this.storeFacade.getState('UserStore');
    readonly newTokenDetector = (event: { data: { type?: any; token?: any; }; }) => {
        if (event.data?.type === 'new-token') {
            const { token } = event.data;
            if (token) {
                localStorage.setItem('token', token);
                window?.parent?.postMessage({ type: 'post-auth' }, '*');
            }
        }
    };

    beforeMount() {
        window.addEventListener('message', this.newTokenDetector);
        window?.parent?.postMessage({ type: 'auth-loaded' }, '*');
        this.otelService.startSpan({ name: 'login' });
    }

    unmounted() {
        window.removeEventListener('message', this.newTokenDetector);
    }

    async mounted() {
        if (this.postAuth) {
            const storedToken = localStorage.getItem('token') || '';
            this.startAuthentication(storedToken);
        } else if (this.token) {
            this.startAuthentication(this.token);
        }
    }

    async startAuthentication(token: string) {
        const tokenToUse = token || '';
        if (tokenToUse) {
            try {
                await this.authService.authentication(tokenToUse);
                this.otelService.endSpan({ name: 'login' }, { sendLogs: true });
                this.$router.push('/').catch(() => {}).finally(() => {
                    this.showGreetingsMessage();
                });
            } catch (error) {
                window.location.href = this.authService.loginUrl;
            }
        } else {
            window.location.href = this.authService.loginUrl;
        }
    }

    showGreetingsMessage() {
        const { user } = this.userService;
        const userName = [user?.firstName, user?.lastName].join(' ');
        const message = !user?.isFirstLogin
            ? this.$tc('user.regularLoginWelcome', 0, [userName])
            : this.$tc('user.firstLoginWelcome', 0, [userName]);

        this.notificationService.success({ title: this.$tc('user.welcome'), message, duration: 5000 });
    }
}
